import { useRef, useState, FC, ReactNode, useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Box } from '@material-ui/core';
import { sentenceCase } from 'change-case';
import mixpanel from 'mixpanel-browser';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { MessageSquare, ShoppingBag } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import CookiesNotification from 'src/components/CookiesNotification';
import ShippingInfoDialog from 'src/components/Dialogs/ShippingInfoDialog';
import {
  CatalogueIcon,
  ChatIcon,
  CheckVerifiedIcon,
  ClipboardIcon,
  DownloadIcon,
  FileMinusIcon,
  GiftIcon,
  HomeIcon,
  InfoIcon,
  RewindIcon,
  StoreIcon,
  UserIcon,
  UserMinusIcon,
} from 'src/components/Icons/navBar';
import StatusActiveIcon from 'src/components/Icons/StatusActiveIcon';
import Badge from 'src/components-for-storybook/molecules/Badge';
import SideMenuBar, {
  listItem,
} from 'src/components-for-storybook/organisms/SideMenuBar/SideMenuBar';
import SwitchRoleModal from 'src/components-for-storybook/organisms/SwitchRoleModal';
import useDesign from 'src/hooks/useDesign';
import SideMenuBarSkeleton from 'src/layouts/DashboardLayout/SideMenuBarSkeleton';
import { useIndexDepartments } from 'src/modules/department/hooks/useIndexDepartments';
import { Department } from 'src/modules/department/validators/departmentValidator';
import { useGetEmployeeAddress } from 'src/modules/employee/hooks/useGetEmployeeAddress';
import useCreateEmployeeRoleForUserMutation from 'src/modules/user/hooks/useCreateEmployeeRoleForUserMutation';
import useSwitchUserRoleMutation from 'src/modules/user/hooks/useSwitchUserRoleMutation';
import useUserProfile from 'src/modules/user/hooks/useUserProfile';
import { redirectTable } from 'src/modules/user/utils/roles';
import { colorSchemeV2 } from 'src/types';
import { analytic } from 'src/utils/analytic';
import zendesk from 'src/utils/zendeskSetup';

import NotebookIcon from '../../components/Icons/NotebookIcon';
import InfoPopup from '../../components/InfoPopup';
import WelcomePopup from '../../components/WelcomePopup';

import DashboardLayoutStyles from './DashboardLayout.styles';

interface DashboardLayoutProps {
  children?: ReactNode;
}

const insertMenuItem = (
  menuItem: listItem<string>,
  menuItems: listItem<string>[],
  position: 'before' | 'after',
  anchor: string,
) => {
  const anchorIndex = menuItems.findIndex(item => item.text === anchor);

  return menuItems.splice(
    position === 'after' ? anchorIndex + 1 : anchorIndex,
    0,
    menuItem,
  );
};

const DashboardLayout: FC<DashboardLayoutProps> = ({ children }) => {
  const classes = DashboardLayoutStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const { mutateAsync: createEmployeeRole } =
    useCreateEmployeeRoleForUserMutation();
  const { mutateAsync: switchRoleMutation } = useSwitchUserRoleMutation();
  const { data: user } = useUserProfile();
  const { logout: logoutAuth0 } = useAuth0();
  const { data: address } = useGetEmployeeAddress(user?.employee?.id);
  const { data: departments } = useIndexDepartments();
  const { navBar, logo, miniCart } = useDesign();
  const { t } = useTranslation('navItems');
  const { i18n } = useTranslation();

  const [shippingDialogOpen, setShippingDialogOpen] = useState<boolean>(false);
  const [welcomeMessage, setWelcomeMessage] = useState(
    user?.role.name === 'employee' &&
      user.employee?.status !== 'accepted_welcome_message' &&
      user.employer
      ? (user.employer.message as string | null)
      : null,
  );
  const [isSwitchDialogOpen, setSwitchDialogOpen] = useState(false);
  const [userMenuItems, setUserMenuItems] = useState<listItem<string>[]>([]);
  const [contentClassName, setContentClassName] = useState<
    'contentWrapperFullscreen' | 'contentWrapperWithSidebar'
  >('contentWrapperWithSidebar');

  const childRef = useRef(null);

  const switchRole = async () => {
    if (user) {
      await switchRoleMutation();

      const dataToTrack: Record<string, number | string | undefined> = {
        user_id: user.id,
        employer_id: user.employer?.id,
        employee_id: user.employee?.id,
      };

      if (user.role.name === 'employee') {
        analytic.track(
          'Switched from employee to employer',
          Object.assign(dataToTrack, {
            from_role: 'employee',
            to_role: user.original_role.name,
          }),
        );
      } else {
        analytic.track(
          'Switched from employer to employee',
          Object.assign(dataToTrack, {
            from_role: user.original_role.name,
            to_role: 'employee',
          }),
        );
      }

      if (user.original_role.name !== user.role.name) {
        window.location.href = redirectTable[user.original_role.name];
      } else {
        window.location.href = redirectTable.employee;
      }
    }
  };

  const handleLogout = async (): Promise<void> => {
    try {
      // mixpanel tracker
      try {
        mixpanel.track('logout', { event_type: 'page view' });
      } catch (error) {
        console.error('mixpanel error', error);
      }

      logoutAuth0({ logoutParams: { returnTo: window.location.origin } });
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        variant: 'error',
      });
    }
  };

  const handleCreateRole = async (department: Department | null) => {
    if (!department) {
      return;
    }

    await createEmployeeRole({ department });

    analytic.track('Creating employee role for employer account', {
      user_id: user?.id,
      employer_id: user?.employer?.id,
      employee_id: user?.employee?.id,
      department_id: department.id,
    });

    setSwitchDialogOpen(false);
    switchRole();
  };

  const handleSwitchRole = async () => {
    if (!user?.employee) {
      setSwitchDialogOpen(true);
      return;
    }

    switchRole();
  };

  useEffect(() => {
    if (user?.role.name === 'employee' && address === null && user.employee) {
      setShippingDialogOpen(true);
    } else {
      setShippingDialogOpen(false);
    }
  }, [address, user]);

  useEffect(() => {
    const textPrimary = navBar.mainText ?? colorSchemeV2.textLight;

    if (
      user?.role.name === 'employer' ||
      user?.role.name === 'employer-admin' ||
      user?.role.name === 'employer-manager'
    ) {
      const employerMenuItems: listItem<string>[] = [
        {
          type: 'subheader',
          text: t('analytics'),
        },
        {
          type: 'menuItem',
          text: t('dashboard'),
          href: '/app/management/dashboard',
          icon: <HomeIcon color={textPrimary} />,
        },
        {
          type: 'subheader',
          text: t('application'),
        },
        {
          type: 'menuItem',
          text: t('team_catalogues'),
          href: '/app/management/products',
          icon: <CatalogueIcon color={textPrimary} />,
        },
        {
          type: 'menuItem',
          text: t('employee_management'),
          href: '/app/management/employees',
          icon: <UserIcon color={textPrimary} />,
        },
        {
          type: 'menuItem',
          text: t('orders'),
          href: '/app/management/orders',
          icon: <DownloadIcon color={textPrimary} />,
        },
        {
          type: 'menuItem',
          text: t('approvals'),
          badge: (
            <Badge
              pendingRequests={user.employer?.total_pending_requests ?? 0}
            />
          ),
          icon: <CheckVerifiedIcon color={textPrimary} />,
          href: '/app/management/requests',
        },
        {
          type: 'subheader',
          text: t('asset_management'),
        },
        {
          type: 'menuItem',
          text: t('all_equipment'),
          href: '/app/asset-management/assets',
          icon: <NotebookIcon />,
        },
        //todo: Create a ticket to clean it with all components
        // {
        //   type: 'menuItem',
        //   text: t('employees'),
        //   href: '/app/management/assets',
        //   icon: (
        //     <span
        //       style={{
        //         fontSize: 18,
        //         paddingTop: 3,
        //         color: 'inherit',
        //       }}
        //     >
        //       <Public fontSize="inherit" color="inherit" />
        //     </span>
        //   ),
        // },
        // {
        //   type: 'menuItem',
        //   text: t('offices'),
        //   href: '/app/management/offices',
        //   icon: <OfficeIcon />,
        // },
        {
          type: 'subheader',
          text: t('help'),
        },
        {
          type: 'menuItem',
          text: t('chat'),
          icon: <ChatIcon color="currentColor" />,
          onClick: () => {
            zendesk.open();
          },
        },
        {
          type: 'menuItem',
          text: t('faq'),
          icon: <InfoIcon color="currentColor" />,
          onClick: () => {
            window.open('https://managementworkwizefaq.zendesk.com/hc/en-gb');
          },
        },
      ];

      if (
        user.employer?.settings
          ?.map(setting => setting.slug)
          .includes('decommission_feature')
      ) {
        insertMenuItem(
          {
            type: 'menuItem',
            text: t('decommissioning'),
            icon: <StatusActiveIcon color={textPrimary} />,
            href: '/app/management/decommissioning',
          },
          employerMenuItems,
          'after',
          t('approvals'),
        );
      }

      // if (
      //   user.employer?.settings
      //     ?.map(setting => setting.slug)
      //     .includes('warehouse')
      // ) {
      //   insertMenuItem(
      //     {
      //       type: 'menuItem',
      //       text: t('warehouse'),
      //       href: '/app/warehouse/assets',
      //       icon: <Home2Icon color={textPrimary} />,
      //     },
      //     employerMenuItems,
      //     'after',
      //     t('employees'),
      //   );
      // }

      setUserMenuItems(employerMenuItems);
    }

    if (user?.role.name === 'employee') {
      const employeeMenuItems: listItem<string>[] = [
        {
          type: 'subheader',
          text: t('application'),
        },
        {
          type: 'menuItem',
          text: t('shop'),
          href: '/app/management/products',
          icon: <StoreIcon color="currentColor" />,
        },
        {
          type: 'menuItem',
          text: t('orders'),
          href: '/app/management/orders',
          icon: <DownloadIcon color="currentColor" />,
        },
        {
          type: 'menuItem',
          text: t('your_home_office'),
          icon: <HomeIcon color="currentColor" />,
          href: '/app/management/employee/assets',
          onClick: () => {},
        },
        {
          type: 'subheader',
          text: t('help'),
        },
        {
          type: 'menuItem',
          text: t('chat'),
          icon: <ChatIcon color="currentColor" />,
          onClick: () => {
            zendesk.open();
          },
        },
        {
          type: 'menuItem',
          text: t('faq'),
          icon: <InfoIcon color="currentColor" />,
          onClick: () => {
            window.open('https://workwizefaq.zendesk.com/hc/en-gb');
          },
        },
      ];

      if (
        user.employer?.settings?.map(setting => setting.slug).includes('perks')
      ) {
        insertMenuItem(
          {
            type: 'menuItem',
            text: t('perks'),
            icon: <GiftIcon color="currentColor" />,
            href: '/app/management/perks',
            onClick: () => {},
          },
          employeeMenuItems,
          'after',
          t('your_home_office'),
        );
      }

      if (user.employer?.announcement) {
        insertMenuItem(
          {
            type: 'menuItem',
            text: sentenceCase(t('announcement')),
            icon: (
              <span
                style={{
                  paddingTop: 3,
                  width: 18,
                  color: 'inherit',
                }}
              >
                <MessageSquare fontSize="inherit" width="100%" />
              </span>
            ),
            href: '/app/management/announcement',
          },
          employeeMenuItems,
          'before',
          t('shop'),
        );
      }

      setUserMenuItems(employeeMenuItems);
    }

    if (user?.role.name === 'admin') {
      setUserMenuItems([
        {
          type: 'subheader',
          text: t('application'),
        },
        {
          type: 'menuItem',
          text: t('catalogues'),
          href: '/app/management/catalogs',
          icon: <CatalogueIcon color={textPrimary} />,
        },
        {
          type: 'menuItem',
          text: 'Products',
          href: '/app/management/products',
          icon: <ShoppingBag color={textPrimary} />,
        },
        {
          type: 'menuItem',
          text: t('clients'),
          href: '/app/management/employers',
          icon: <UserIcon color={textPrimary} />,
        },
        {
          type: 'menuItem',
          text: t('orders'),
          href: '/app/management/orders',
          icon: <DownloadIcon color={textPrimary} />,
        },
        {
          type: 'expandableMenuItem',
          text: t('requests'),
          icon: <ClipboardIcon color={textPrimary} />,
          onClick: () => {},
          insets: [
            {
              text: t('order_cancellations'),
              href: '/app/management/request-cancellations',
              icon: <FileMinusIcon color={textPrimary} />,
            },
            {
              text: t('order_returns'),
              icon: <RewindIcon color={textPrimary} />,
              href: '/app/management/request-returns',
            },
            {
              text: t('offboardings'),
              href: '/app/management/requests-offboards',
              icon: <UserMinusIcon color={textPrimary} />,
            },
          ],
        },
        {
          type: 'menuItem',
          text: t('perks'),
          href: '/app/management/perks',
          icon: <GiftIcon color={textPrimary} />,
        },
      ]);
    }
  }, [user?.role, i18n.language]);

  useEffect(() => {
    if (history.location.state) {
      const historyState = history.location.state as {
        repair?: { number: string };
      };

      if (historyState.repair) {
        setContentClassName('contentWrapperFullscreen');
      }
    } else {
      setContentClassName('contentWrapperWithSidebar');
    }
  }, [history.location.state]);

  return (
    <>
      {user?.read_privacy === 0 && user.role.name === 'employee' ? (
        <CookiesNotification />
      ) : null}

      <div className={classes.root}>
        {contentClassName === 'contentWrapperWithSidebar' &&
          (!user || userMenuItems.length === 0 ? (
            <Box
              position="absolute"
              top={0}
              left={0}
              height="100%"
              width={255}
              p={3}
              display="flex"
              gridGap={20}
              flexDirection="column"
            >
              <SideMenuBarSkeleton />
            </Box>
          ) : (
            <SideMenuBar
              companyLogo={logo.url}
              user={user}
              handleLogout={handleLogout}
              handleSwitchRole={handleSwitchRole}
              nextRole={user.role.name === 'employee' ? 'employer' : 'employee'}
              listItems={userMenuItems}
              colorScheme={
                user.role.name === 'employee'
                  ? {
                      textPrimary: navBar.mainText ?? '',
                      textSecondary: miniCart.secondary ?? '',
                      drawer: {
                        background: navBar.background ?? '',
                      },
                      budget: {
                        background: navBar.budgetContainerFull ?? '',
                        textPrimary: navBar.budgetTextFull ?? '',
                        textSecondary: navBar.budgetTextFull ?? '',
                        textEmpty: navBar.budgetTextEmpty ?? '',
                      },
                      settingsBlock: {
                        expandedBackground: navBar.background ?? '',
                        collapsedBackground: navBar.background ?? '',
                        textPrimary: navBar.mainText ?? '',
                        hover: miniCart.mainButton ?? '',
                      },
                      active: {
                        background: miniCart.mainButton ?? '',
                        textPrimary: miniCart.mainButtonText ?? '',
                      },
                    }
                  : undefined
              }
            />
          ))}

        <div className={classes[contentClassName]}>
          <div className={classes.contentContainer} id="content-container">
            <div className={classes.content}>{children}</div>
          </div>
        </div>

        {welcomeMessage && (
          <WelcomePopup
            text={welcomeMessage}
            afterClose={() => setWelcomeMessage('')}
          />
        )}

        {isMobile && !localStorage.getItem('mobileDevicePopup') && (
          <InfoPopup
            ref={childRef}
            text={
              'Please use our desktop version to navigate through the site. \n' +
              'We are currently working on mobile version improvement.'
            }
            afterClose={() => localStorage.setItem('mobileDevicePopup', '1')}
          />
        )}
      </div>

      {!welcomeMessage && (
        <ShippingInfoDialog
          open={shippingDialogOpen}
          onClose={() => {}}
          isUpdateForm={false}
          useUserAuthId={true}
        />
      )}

      {user && departments && (
        <SwitchRoleModal
          handleClose={() => setSwitchDialogOpen(false)}
          isOpen={isSwitchDialogOpen}
          handleCreateRole={handleCreateRole}
          departments={departments as Department[]}
        />
      )}
    </>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;
